@import './../../../assets/styles/variables';

.history-class {
  padding: 15px;
  font-size: 10px;
  border: 3px solid;
  text-align: center;

  @media (min-width: $lg) {
    padding: 30px 20px;
  }

  &__item:not(:last-child) {
    margin-bottom: 8px;

    @media (min-width: $lg) {
      margin-bottom: 12px;
    }
  }

  &__date {
    font-size: 16px;
  }

  &__image {
    margin-left: auto;
    margin-right: auto;
    width: 85px;
    height: 80px;
  }

  &__trainer-name {
    height: 26px;
  }

  &__btn {
    padding: 6px 10px;
    height: auto;
    color: $black;
    border-radius: 20px;
  }
}
