@import "./src/assets/styles/variables";

.overlay {
  transform: translateY(-100%);
  position: fixed;
  top: 0;
  z-index: 40;
  padding: 48px 0;
  width: 100%;
  height: 100vh;
  background: $graphite;
  overflow-y: auto;
  transition: .2s ease;

  @media (min-width: $md) {
    padding-top: 30px;
    padding-bottom: 30px;
  }

  &__header,
  &__body,
  &__footer {
    padding-left: 45px;

    @media (min-width: $md) {
      padding-left: 50px;
    }

    &:not(:last-child) {
      margin-bottom: 50px;
    }
  }

  &__footer {
    margin-top: -20px;
  }

  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;

    @media (min-width: $md) {
      padding-left: 0;
    }
  }

  &__close {
    display: flex;
    color: $white;
  }

  &__logo {
    max-width: 132px;
  }

  &__list {
    margin-top: 32px;

    @media (min-width: $md) {
      margin-top: 48px;
    }

    &-item {
      font-family: $font-title;
      font-size: 24px;
      line-height: 1;

      &:not(:last-child) {
        margin-bottom: 32px;

        @media (min-width: $md) {
          margin-bottom: 48px;
        }
      }
    }
  }

  &__icons {
    display: flex;
    gap: 12px;

    &-user,
    &-logout {
      width: 32px;
      height: 32px;

      svg {
        width: 100%;
        height: 100%;
      }

      @media (min-width: $md) {
        width: 48px;
        height: 48px;
      }
    }

    &-logout {
      display: flex;
      align-items: center;
      justify-content: center;

      svg {
        width: 75%;
        height: 75%;
      }
    }

    .social__icon {
      width: 32px;
      height: 32px;

      @media (min-width: $md) {
        width: 48px;
        height: 48px;
      }
    }
  }

  &--open {
    transform: translateY(0);
  }
}

// Accordion
.accordion {
  padding-left: 20px;
  max-height: 0;
  font-family: $font-title;
  font-size: 18px;
  overflow: hidden;
  transition: .2s ease;

  @media (min-width: $md) {
    padding-left: 40px;
  }

  &__item {
    &:not(:last-child) {
      margin-bottom: 24px;
    }

    a {
      transition: .2s ease;
    }
  }

  &.accordion--active {
    max-height: 400px;
    margin-top: 30px;
  }

  &__item a:hover {
    color: $yellow;
  }

  &__btn {
    position: relative;
    font-size: 24px;
    cursor: pointer;

    &-icon {
      position: absolute;
      right: -40px;
      margin-top: 5px;
      width: 21px;
      height: 13px;
      color: $green;
      transition: .2s ease;

      &.open {
        transform: rotateX(180deg);
        margin-top: 3px;
      }

      svg {
        display: block;
        width: 100%;
        height: 100%;
      }
    }
  }

  &__btn img {
    width: 26px;
    height: 16px;
  }
}

.abonement-informer--mob-menu {
  .abonement-informer__link-text {
    display: block;
    font-size: 12px;
    
    @media (min-width: $xs) {
      font-size: 16px;
    }
  }

  .abonement-informer__link {
    padding: 0 20px;
    height: 45px;
    border-right: 0;
  }

  .abonement-informer__link-icon {
    width: 30px;
  }

  .abonement-informer__icon {
    z-index: 1;
    height: 48px;
    width: auto;
    top: -2px;
  }

  .abonement-informer__icon--right {
    right: -22px;
  }

  .abonement-informer__icon--left {
    left: -22px;
  }
}
