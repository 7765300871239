@import "../../assets/styles/variables";

.static-page {
  &__title {
    @media (max-width: $max-md) {
      font-size: 25px;
    }
  }

  &__image {
    max-height: 500px;
    object-fit: contain;
  }
}
