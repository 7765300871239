@import "../../assets/styles/variables";

// Account page grid
.account {
  color: $white;

  @media (min-width: $md) {
    display: flex;
    flex-wrap: wrap;
  }

  &-block-border {
    padding: 25px;
    border: $border-2;

    @media (min-width: $md) {
      padding: 15px;
      box-shadow: $shadow-inset-2
    }

    @media (min-width: $lg) {
      padding: 20px;
      box-shadow: $shadow-inset-3;
    }

    &--mob {
      @media (max-width: $max-md) {
        padding: 0;
        border: 0;
      }
    }
  }

  &__info,
  &__level,
  &__informers {
    @media (max-width: $max-md) {
      margin-bottom: 30px;
    }
  }

  .user-preview,
  .achievements {
    @media (max-width: $max-md) {
      margin-bottom: 30px;
    }
  }

  &__info {
    @media (min-width: $sm) and (max-width: $md) {
      position: relative;
    }

    @media (min-width: $md) {
      display: flex;
      flex-direction: column-reverse;
      gap: 10px;
      flex: 1 1 calc(77% - 11px);
      padding-right: 10px;
    }

    @media (min-width: $lg) {
      flex-basis: calc(48.67% - 7px);
      justify-content: space-between;
      padding-right: 17%;
    }
  }

  &__level {
    @media (min-width: $md) {
      flex: 1 1 calc(23% - 7px);
    }

    @media (min-width: $lg) {
      max-width: 200px;
    }

    .user-level {
      @media (min-width: $md) {
        position: relative;
        clip-path: polygon(60px 0, 100% 0, 100% 100%, 0 100%, 0 60px);
      }

      &:before {
        @media (min-width: $md) {
          content: '';
          transform: rotate(45deg);
          position: absolute;
          top: -15px;
          left: 28px;
          width: 2px;
          height: 85px;
          background-color: $green;
          box-shadow: $shadow-pseudo-inset;
        }
      }
    }
  }

  &__informers {
    @media (min-width: $md) {
      display: flex;
      align-items: stretch;
      gap: 10px;
      flex: 1 1 100%;
      margin-top: -120px;
    }

    @media (min-width: $lg) {
      flex-basis: calc(33.33% - 7px);
      order: -1;
      flex-direction: column;
      align-items: stretch;
      gap: 10px;
      margin-top: 0;
      max-width: 360px;
    }

    .achievements {
      @media (min-width: $md) {
        position: relative;
        flex: 1 1 65%;
        margin-top: auto;
        max-height: calc(100% - 129px);
        height: 100%;
        clip-path: polygon(40px 0, 100% 0, 100% calc(100% - 15px), calc(100% - 15px) 100%, 0 100%, 0 40px);
      }

      @media (min-width: $lg) {
        max-width: 180px;
        min-height: 181px;
        clip-path: polygon(40px 0, 100% 0, 100% 100%, 0 100%, 0 40px);
      }

      &:before,
      &:after {
        @media (min-width: $md) {
          content: '';
          position: absolute;
          height: 2px;
          background-color: $green;
          box-shadow: $shadow-pseudo-inset;
        }
      }

      &:before {
        @media (min-width: $md) {
          transform: rotate(-45deg);
          top: 18px;
          left: -14px;
          width: 64px;
        }
      }

      &:after {
        @media (min-width: $md) {
          transform: rotate(45deg);
          right: 6px;
          bottom: -36px;
          width: 2px;
          height: 82px;
        }

        @media (min-width: $lg) {
          content: none;
        }
      }

      &__bottom-decor {
        @media (min-width: $md) and (max-width: $max-lg) {
          position: absolute;
          bottom: -1px;
          right: 13px;
          width: 2px;
          height: calc(100% - 76px);
          background: $green;
        }
      }
    }

    .trainings {
      @media (min-width: $md) {
        position: relative;
        flex: 1 1 35%;
        clip-path: polygon(calc(100% - 35px) 0, 100% 35px, 100% 100%, 15px 100%, 0 calc(100% - 15px), 0 140px, 110px 0);
      }

      @media (min-width: $lg) {
        margin-right: 37px;
        clip-path: polygon(calc(100% - 35px) 0, 100% 35px, 100% 100%, 15px 100%, 0 calc(100% - 15px), 0px 123px, calc(100% - 182px) 0);
      }

      &:before,
      &:after {
        @media (min-width: $md) {
          content: '';
          position: absolute;
          height: 2px;
          background-color: $green;
          box-shadow: $shadow-pseudo-inset;
        }
      }

      &:before {
        @media (min-width: $md) {
          transform: rotate(-52deg);
          top: 69px;
          left: -36px;
          width: 178px;
        }

        @media (min-width: $lg) {
          transform: rotate(-41deg);
          left: -54px;
          width: 225px;
        }
      }

      &:after {
        @media (min-width: $md) {
          transform: rotate(45deg);
          top: 15px;
          right: -9px;
          width: 50px;
        }
      }

      &__bottom-decor {
        @media (min-width: $md) {
          transform: rotate(45deg);
          position: absolute;
          bottom: 5px;
          left: -5px;
          width: 22px;
          height: 2px;
          background-color: $green;
          box-shadow: $shadow-pseudo-inset;
        }
      }

      &,
      &__item-wrapper {
        @media (min-width: $lg) {
          transform: scale(-1, 1);
        }
      }

      &__item-wrapper {
        @media (min-width: $lg) {
          padding-left: 10px;
          padding-top: 10px;
        }
      }
    }
  }
}

.user-preview {
  position: relative;

  @media (max-width: $max-md) {
    display: flex;
    align-items: center;
    gap: 15px;
  }

  @media (min-width: $md) {
    align-self: flex-end;
    width: calc(100% - 125px);
  }

  @media (min-width: $lg) {
    position: absolute;
    width: calc(100% - 180px);
    height: 100%;
  }

  &__image {
    flex-shrink: 0;
    padding: 9px;
    width: 75px;
    height: 75px;
    border-radius: 50%;
    background-color: $graphite;
    box-shadow: 0 0 0 2px $graphite;

    @media (min-width: $md) {
      position: absolute;
      right: -110px;
      bottom: 80px;
      z-index: 1;
      padding: 20px;
      width: 220px;
      height: 220px;
      box-shadow: 0 0 0 8px $graphite, $shadow-inset-2;
    }

    @media (min-width: $lg) {
      right: -230px;
      top: 200px;
      padding: 30px;
      width: 310px;
      height: 310px;
      border-width: 3px;
      box-shadow: 0 0 0 11px $graphite, $shadow-inset-3;
    }

    @media (min-width: $xl) {
      top: 160px;
      right: -250px;
      padding: 45px;
      width: 350px;
      height: 350px;
    }

    svg {
      width: 100%;
      height: 100%;
    }

    img {
      width: 100%;
      height: 100%;
      border-radius: 50%;
      object-fit: cover;
    }
  }
}

.user-info {
  @media (min-width: $md) {
    order: 1;
    width: 72%;
    margin-left: auto;
  }
}
