@import "./src/assets/styles/variables";

.footer {
  padding: 60px 0;

  // Divider
  &__divider {
    margin: 32px 0;
    border: $border-1;
  }

  // Menu
  &__nav {
    @media (max-width: $max-md) {
      display: flex;
      justify-content: center;
    }
  }

  &-menu {
    font-size: 24px;
    font-family: $font-title;

    @media (min-width: $md) {
      display: flex;
      justify-content: center;
      gap: 95px;
      font-size: 14px;
    }

    @media (min-width: $lg) {
      gap: 80px;
      font-size: 16px;
    }

    &__item {
      &:not(:last-child) {
        @media (max-width: $max-md) {
          margin-bottom: 32px;
        }
      }

      a {
        display: block;
      }
    }
  }
  &__logo,
  .social,
  &__policy {
    &:not(:last-child) {
      margin-bottom: 32px;
    }
  }

  // Logo
  &__logo {
    margin: 0 auto 32px;
    max-width: 280px;

    @media (min-width: $md) and (max-width: $max-lg) {
      max-width: 215px;
    }
  }

  // Policy
  &__policy {
    text-align: center;

    @media (min-width: $md) {
      display: flex;
      gap: 50px;
      justify-content: center;
    }

    &-item {
      color: $white;

      &:not(:last-child) {
        @media (max-width: $max-md) {
          margin-bottom: 15px;
        }
      }

      a {
        display: inline-block;
      }
    }
  }
}
