@font-face {
  font-family: 'Docker';
  font-style:  normal;
  font-weight: 400;
  font-display: swap;
  src: url("./docker-one.woff") format("woff"),
}
@font-face {
  font-family: 'Roboto';
  font-style:  normal;
  font-weight: 400;
  font-display: swap;
  src: url("./roboto-regular.woff") format("woff"),
}

@font-face {
  font-family: 'Open Sans';
  font-style:  normal;
  font-weight: 300;
  font-display: swap;
  src: url("./OpenSans-Light.ttf") format("ttf"),
}

@font-face {
  font-family: 'Open Sans';
  font-style:  normal;
  font-weight: 400;
  font-display: swap;
  src: url("./open-sans.woff") format("woff"),
}
@font-face {
  font-family: 'Open Sans';
  font-style:  normal;
  font-weight: 600;
  font-display: swap;
  src: url("./open-sans-semibold.woff") format("woff"),
}
@font-face {
  font-family: 'Open Sans';
  font-style:  normal;
  font-weight: 700;
  font-display: swap;
  src: url("./open-sans-semibold.woff") format("woff"),
}
@font-face {
  font-family: 'Bebas';
  font-style:  normal;
  font-weight: 700;
  font-display: swap;
  src: url("./bebas-neue-bold.woff") format("woff"),
}
@font-face {
  font-family: 'Inter';
  font-style:  normal;
  font-weight: 400;
  font-display: swap;
  src: url("./Inter-Regular.woff") format("woff"),
}



