@import "../../assets/styles/variables";

// Page heading with subtitle
.schedule-subtitle-wrapper {
  margin-bottom: 30px;

  @media (min-width: $md) {
    margin-bottom: 40px;
  }

  @media (min-width: $lg) {
    display: flex;
    align-items: center;
    gap: 17px;
    margin-bottom: 60px;
  }

  .page-subtitle {
    position: relative;
    flex-grow: 1;
    border: $border-1;

    @media (min-width: $lg) {
      margin-bottom: 0;
    }
  }

  .schedule-subtitle-image {
    width: 100%;
    overflow: hidden;

    picture {
      display: block;
      height: 100%;
    }

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  .subtitle-level {
    @media (max-width: $max-lg) {
      display: flex;
      flex-wrap: wrap;
      gap: 30px;
    }

    @media (min-width: $lg) {
      min-width: 255px;
    }

    &__item {
      @media (max-width: $max-xs) {
        flex: 0 0 calc(50% - 15px);
      }

      @media (min-width: $xs) and (max-width: $max-lg) {
        flex-basis: calc(33.33% - 20px);
      }

      @media (min-width: $md) {
        display: flex;
        align-items: center;
        gap: 12px;
      }

      &:not(:last-child) {
        @media (min-width: $lg) {
          margin-bottom: 10px;
        }
      }

      @media (min-width: $lg) {
        min-width: 260px;
      }

      &-image {
        width: 30px;
        height: 30px;
        border: 4px solid;

        @media (max-width: $max-md) {
          margin: 0 auto 15px;
        }
      }

      &-title {
        display: block;
        padding-top: 5px;
        font-family: $font-title;
        font-size: 14px;
        color: $white;

        @media (max-width: $max-md) {
          text-align: center;
        }

        @media (min-width: $lg) {
          font-size: 18px;
        }
      }
    }
  }
}

// Filter week
.schedule__filter {
  @media (min-width: $xs) {
    display: flex;
    align-items: center;
  }
}

.filter-week {
  position: relative;
  margin-left: auto;
  padding: 0 20px;

  @media (min-width: $sm) {
    padding: 0 30px;
  }

  &__btn {
    transform: translateY(-50%);
    position: absolute;
    top: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 5px;
    color: $white;
    cursor: pointer;

    &--prev {
      left: -5px;
    }

    &--next {
      right: -5px;
    }
  }

  &__text {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 25px;
    height: 30px;
    font-family: $font-title;
    font-size: 12px;
    background-color: $yellow;
    color: $graphite;
    border-radius: 20px;
    line-height: 1;

    @media (min-width: $xs) {
      width: 170px;
    }

    @media (min-width: $lg) {
      font-size: 14px;
      width: 190px;
    }
  }
}

// Schedule table
.schedule {
  &__table {
    margin-top: 25px;
    color: $white;
    font-family: $font-title;
    border: 1px solid rgba(255, 255, 255, 0.4);
    overflow: auto;

    .join-class-error {
      position: fixed;
      top: 0;
      right: 0;
      z-index: 1401;
      width: 50%;
      font-size: 16px;
      border: 1px solid $green;
      border-radius: 0;
      background: $graphite;

      &,
      .MuiAlert-icon {
        color: $yellow;
      }
    }
  }

  &__header {
    .schedule__column {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      height: 50px;

      @media (min-width: $lg) {
        height: 80px;
      }

      &:not(:first-child) {
        font-size: 10px;

        @media (min-width: $lg) {
          font-size: 12px;
        }
      }

      &:first-child {
        font-size: 10px;

        @media (min-width: $lg) {
          font-size: 16px;
        }
      }
    }
  }

  &__body {
    .schedule__column {
      display: flex;
      flex-direction: column;

      &:not(:first-child) {
        padding-top: 0;
        padding-bottom: 0;
      }

      &:first-child {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        background: #454545;
        font-size: 8px;
        text-align: center;

        @media (min-width: $lg) {
          font-size: 12px;
        }

        .schedule__column-icon {
          width: 30px;
          margin-bottom: 5px;
        }
      }
    }
  }

  &__row {
    display: flex;
  }

  &__column {
    flex: 1 1 100%;
    padding: 10px;
    min-width: 85px;
    border: 1px solid rgba(255, 255, 255, .4);
    word-break: break-word;

    @media (min-width: $lg) {
      padding: 17px;
      min-width: 138px;
    }
  }

  .no-class {
    justify-content: center;
    padding: 20px;
    font-size: 24px;
  }
}
