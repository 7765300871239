@import "../../../assets/styles/variables";

.popup-wrapper {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1;
  height: 100vh;
  background: rgba(0, 0, 0, .2);

  &.open {
    width: 100%;

    .popup {
      top: 50%;
    }
  }
}

.popup {
  transform: translate(-50%, -50%);
  position: fixed;
  top: -100%;
  left: 50%;
  padding: 16px;
  width: calc(100% - 30px);
  max-height: 90vh;
  border: 6px solid $green;
  background: $graphite;
  transition: .4s ease;

  @media (min-width: $md) {
    padding: 40px;
    width: calc(100% - 60px);
  }

  @media (min-width: $lg) {
    padding: 60px;
    border-width: 10px;
  }

  &__title {
    margin-bottom: 30px;
    padding-right: 60px;
    font-size: 20px;

    @media (min-width: $xs) {
      font-size: 24px;
    }

    @media (min-width: $lg) {
      margin-bottom: 40px;
      font-size: 30px;
    }
  }

  &__content {
    max-height: calc(90vh - 106px);
    overflow: auto;

    @media (min-width: $md) {
      max-height: calc(90vh - 155px);
    }

    @media (min-width: $lg) {
      max-height: calc(90vh - 220px);
    }
  }

  &__close {
    position: absolute;
    right: 16px;
    top: 16px;
    cursor: pointer;

    @media (min-width: $md) {
      right: 40px;
      top: 40px;
    }

    @media (min-width: $lg) {
      right: 60px;
      top: 60px;
    }
  }

  &.edit-profile-popup {
    @media (min-width: $lg) {
      max-width: 1110px;
    }
  }
}

// Edit profile popup form
.form {
  &__group {
    margin-bottom: 20px;

    @media (min-width: $lg) {
      margin-bottom: 30px;
    }
  }

  &__label {
    display: block;

    &-name {
      position: relative;
      display: inline-block;
      margin-top: 10px;
      width: 100%;
      font-size: 14px;
      font-weight: 700;
      color: $grey;

      .required {
        color: $red;
      }

      .error-msg {
        position: absolute;
        top: 0;
        right: 0;
        font-size: 12px;
        color: $red;
      }
    }
  }

  &__input {
    padding: 6px 0;
    width: 100%;
    font-size: 18px;
    font-weight: 700;
    color: $green;
    border-bottom: 1px solid #e5e5e5;

    &.MuiInput-root {
      font-size: 18px;
      font-weight: 700;
      line-height: 22px;
      color: $green;

      &:before,
      &:after {
        content: none;
      }

      input {
        padding: 0;
        height: auto;

        &::placeholder {
          color: $white;
        }
      }
    }
  }

  &__textarea {
    padding: 10px;
    width: 100%;
    min-height: 150px;
    max-height: 300px;
    height: 35px;
    border: 1px solid #e5e5e5;
    color: $green;
    resize: vertical;
  }
}

.edit-profile-popup .form__body {
  @media (min-width: $md) {
    display: flex;
    flex-wrap: wrap;
    gap: 0 20px;
  }

  @media (min-width: $lg) {
    position: relative;
    gap: 0 40px;
    padding-right: 20px;
  }
}

.form__group {
  &--name,
  &--surname,
  &--about,
  &--avatar {
    @media (min-width: $md) {
      flex: 1 1 100%;
    }
  }

  &--birthday,
  &--height,
  &--experience {
    @media (min-width: $md) {
      flex: 1 1 calc(33.33% - 15px);
    }
  }

  &--telegram,
  &--phone,
  &--role {
    @media (min-width: $md) {
      flex: 1 1 calc(50% - 10px);
    }
  }

  &--avatar {
    @media (min-width: $lg) {
      position: absolute;
      bottom: 0;
      left: 0;
    }
  }

  &--birthday,
  &--height,
  &--experience,
  &--telegram,
  &--phone,
  &--name,
  &--role,
  &--surname {
    @media (min-width: $lg) {
      flex-basis: calc(50% - 20px);
    }
  }

  &--phone {
    @media (min-width: $lg) {
      display: flex;
      align-items: flex-end;

      & .form__label {
        width: 100%;
      }
    }
  }

  &--about {
    @media (min-width: $lg) {
      padding-left: 160px;
    }
  }
}


.form__submit {
  display: block;
  margin: 0 auto;
  max-width: 300px;
  height: 50px;
  clip-path: polygon(0% 30%, 8% 0%, 92% 0%, 100% 30%, 100% 70%, 92% 100%, 8% 100%, 0 70%);
}

.edit-profile-form {
  .form__input::placeholder {
    color: $grey;
  }

  .avatar {
    margin: 0 auto;
    width: 120px;
    height: 120px;
    color: $white;
    border-radius: 50%;
    overflow: hidden;

    svg,
    img {
      width: 100%;
      height: 100%;
    }

    img {
      object-fit: cover;
    }

    &+.form__label-name {
      display: block;
      text-align: center;
    }
  }

  .form-file {
    position: relative;
    margin-top: 10px;
    text-align: center;

    button {
      width: 80px;
      height: 30px;
      font-size: 10px;
    }

    &__input {
      transform: translateX(-50%);
      position: absolute;
      top: 0;
      left: 50%;
      width: 80px;
      height: 30px;
      opacity: 0;
    }
  }
}

// Select
.edit-profile-form__select {
  .select__control {
    background: transparent;
    border: 0;
    border-radius: 0;
    border-bottom: 1px solid #e5e5e5;
    box-shadow: none;
  }

  .select__value-container {
    gap: 5px 10px;
    padding-top: 6px;
    padding-bottom: 6px;
    padding-left: 0;
  }

  .select__input-container {
    margin: 0;
    color: $green;
  }

  .select__multi-value {
    margin: 0;
    color: $white;
    background: $green;
    border-radius: 0;

    &__label {
      font-weight: 700;
      color: $white;
    }
  }

  .select__menu {
    border-radius: 0;
  }
}
