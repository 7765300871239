@import './../../assets/styles/variables';

.popup {
  &.comment-form-popup {
    .popup__title {
      @media (min-width: $md) {
        margin-bottom: 40px;
      }

      @media (min-width: $lg) {
        margin-bottom: 60px;
      }
    }

    @media (min-width: $lg) {
      max-width: 1110px;
    }
  }
}

.comment-form {
  &.form {
    gap: 0;
  }

  .form__group {
    margin-bottom: 30px;

    @media (min-width: $md) {
      margin-bottom: 40px;
    }

    @media (min-width: $lg) {
      margin-bottom: 60px;
    }
  }

  .form__label--rating {
    display: inline-flex;
    flex-direction: column;

    .MuiRating-root {
      color: $green;
    }

    .MuiRating-icon svg {
      width: 40px;
      height: 40px;

      path {
        fill: $green;
      }
    }
  }

  .form__label-name {
    margin-top: 0;
    margin-bottom: 10px;
    font-family: $font-title;
    font-size: 16px;
    font-weight: 400;
    color: $white;
    line-height: 1;

    @media (min-width: $xs) {
      font-size: 18px;
    }

    @media (min-width: $md) {
      font-size: 20px;
    }

    @media (min-width: $lg) {
      font-size: 24px;
    }
  }

  .form__textarea {
    padding: 24px;
    height: 250px;
    font-size: 24px;
    color: $white;
  }

  .form__footer {
    @media (min-width: $sm) {
      display: flex;
      gap: 35px;
    }
  }

  .form__btn,
  .form__submit {
    margin: 0;
    max-width: none;
    clip-path: none;

    @media (min-width: $sm) {
      flex: 1 1 50%;
      order: inherit;
    }

    &:not(:last-child) {
      @media (max-width: $max-sm) {
        margin-bottom: 30px;
      }
    }
  }

  .form__btn {
    border-width: 2px;
    background-color: transparent;
    transition: .5s ease;

    @media (any-hover: hover) {
      &:hover {
        border-color: $green;
        background-color: $green;
      }
    }
  }
}

.comment-form-popup .add-comment-error {
  position: relative;
  top: -40px;
  left: 0;
  z-index: 1401;
  font-size: 16px;
  border: 1px solid $green;
  border-radius: 0;
  background: $graphite;

  &,
  .MuiAlert-icon {
    color: $yellow;
  }
}
