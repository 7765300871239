@import "./src/assets/styles/variables";

.social {
  display: flex;
  justify-content: center;
  gap: 12px;

  &__icon {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 20px;
    height: 20px;

    @media (min-width: $xs) {
      width: 24px;
      height: 24px;
    }

    svg {
      width: 100%;
      height: 100%;
    }
  }
}
