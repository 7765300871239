@import "../../../assets/styles/variables";

.filter-btn-mob {
  width: 100%;
  height: 30px;
  font-size: 12px;
  border: 1px solid $white;
  border-radius: 20px;
  line-height: 1;

  @media (max-width: $max-xs) {
    margin-bottom: 30px;
  }

  @media (min-width: $xs) {
    max-width: 130px;
  }

  @media (min-width: $md) {
    max-width: 180px;
  }
}

.MuiDrawer-paper.filter-menu-wrapper-mob {
  max-width: 500px;
  width: 100%;
  background: $graphite;
}

.filter-menu-mob {
  position: relative;
  padding: 30px;
  font-family: $font-title;
  color: $white;

  &__heading {
    margin-bottom: 50px;
  }

  &__title {
    font-size: 24px;
  }

  &__body {
    padding-left: 20px;

    @media (min-width: $xs) {
      padding-left: 40px;
    }
  }

  &__accordion {
    &.MuiAccordion-root {
      margin-bottom: 50px;
      color: $white;
      background: transparent;
      box-shadow: none;

      &:before {
        content: none;
      }
    }

    .MuiAccordionSummary-root {
      justify-content: flex-start;
      gap: 5px;
      padding: 0;
      font-size: 18px;
      min-height: auto;

      @media (min-width: $xs) {
        font-size: 22px;
      }
    }

    .MuiAccordionSummary-content {
      flex-grow: 0;
      margin: 0;
      line-height: 1;
    }

    .MuiAccordionSummary-expandIconWrapper {
      position: relative;
      top: -3px;
      color: $green;
    }

    .MuiAccordionDetails-root {
      padding-top: 16px;
    }
  }

  &__list {
    font-family: $font-text;

    li:not(:last-child) {
      margin-bottom: 10px;
    }

    .selected {
      color: $yellow;
    }
  }

  &__close {
    position: absolute;
    top: 20px;
    right: 20px;
    display: flex;
  }
}
