@import "../../../assets/styles/variables";

.dropdown {
  box-sizing: content-box;
  position: relative;
  display: inline-block;

  &__btn {
    display: flex;
    align-items: center;
    gap: 5px;
    padding-top: 10px;
    padding-bottom: 10px;
    font-family: $font-text;
    font-size: 18px;
    color: $white;
    cursor: pointer;
    border: none;
    transition: .2s ease;

    @media (any-hover: hover) {
      &:hover {
        color: $yellow;

        .dropdown__menu-icon {
          transform: rotateX(180deg);
        }

        .dropdown__menu {
          transform: translateY(0);
          opacity: 1;
          visibility: visible;
        }
      }
    }
  }

  &__menu {
    transform: translateY(-15px);
    position: absolute;
    top: 44px;
    z-index: 2;
    padding: 15px;
    width: max-content;
    background-color: $graphite;
    transition: .2s ease;
    opacity: 0;
    visibility: hidden;

    &-icon {
      display: flex;
      color: $green;
      transition: .2s ease;
    }

    &-item {
      display: block;

      &:not(:last-child) {
        margin-bottom: 20px;
      }
    }
  }
}
