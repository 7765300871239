@import "./src/assets/styles/variables";

.main-slider .swiper {
  height: 412px;

  @media (min-width: $md) {
    height: 330px;
  }

  @media (min-width: $lg) {
    height: 490px;
  }

  &-slide {
    height: 355px;
    cursor: pointer;

    @media (min-width: $md) {
      height: 280px;
    }

    @media (min-width: $lg) {
      height: 430px;
    }
  }
}
