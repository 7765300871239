@import "../../../assets/styles/variables";

// Class reviews popup grid
.class-reviews {
  &-popup-wrapper.popup-wrapper {
    z-index: 1401;
  }

  &-popup {
    max-width: 1110px;

    .popup__close {
      right: 26px;

      @media (min-width: $md) {
        right: 50px;
      }

      @media (min-width: $lg) {
        right: 70px;
      }
    }
  }

  &__block {
    &:not(:last-child) {
      margin-bottom: 60px;
    }

    .page-title {
      display: flex;
      flex-wrap: wrap;
      gap: 15px;
      margin-bottom: 30px;
      font-size: 18px;

      @media (min-width: $sm) {
        align-items: center;
        justify-content: space-between;
      }

      @media (min-width: $lg) {
        font-size: 30px;
      }

      span {
        padding-top: 6px;
      }
    }
  }

  &.popup__content {
    padding-right: 5px;
    max-height: calc(90vh - 44px);

    @media (min-width: $md) {
      max-height: calc(90vh - 92px);
    }

    @media (min-width: $lg) {
      max-height: calc(90vh - 140px);
    }
  }

  &__add-review-notification {
    padding: 10px;
    font-family: $font-text;
    font-size: 12px;
    color: $yellow;
    border: 1px solid $yellow;
    line-height: 1.33;

    @media (min-width: $lg) {
      font-size: 14px;
    }

    a {
      font-weight: 600;
      color: $green;
    }
  }
}

// Class info
.class-info {
  font-family: $font-title;
  color: $white;

  // Info header
  &__header {
    position: relative;
    margin-bottom: 24px;
    padding-right: 60px;
  }

  &__title {
    display: inline-block;
    font-size: 18px;

    @media (max-width: $max-md) {
      margin-bottom: 25px;
    }

    @media (min-width: $lg) {
      font-size: 30px;
    }
  }

  &__rating {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    gap: 10px;
    line-height: 1;
    font-size: 14px;

    @media (min-width: $md) {
      transform: translateY(-50%);
      position: absolute;
      top: 50%;
      right: 50px;
    }

    @media (min-width: $lg) {
      right: 60px;
    }

    &-title {
      padding-top: 5px;
    }

    .MuiRating-root {
      color: $green;
      gap: 5px;
    }

    .MuiRating-icon svg {
      width: 30px;
      height: 30px;

      @media (min-width: $lg) {
        width: 40px;
        height: 40px;
      }

      path {
        fill: $green;
      }
    }
  }

  // Info body
  &__body {
    display: flex;
    gap: 40px;

    @media (max-width: $max-xl) {
      flex-wrap: wrap;
    }

    @media (min-width: $xl) {
      gap: 60px;
    }
  }

  &__trainer {
    &-name {
      display: inline-block;
      margin-bottom: 10px;
      font-size: 24px;
      line-height: 1;
    }

    &-image {
      flex: 1 1 auto;
      min-width: 210px;
      max-width: 210px;
      height: 185px;
      border-radius: 10px;
      clip-path: polygon(120px 0, 135px 10px, 100% 10px, 100% calc(100% - 15px), calc(100% - 15px) 100%, 10px 100%, 10px 115px, 0 105px, 0 0);
      overflow: hidden;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
  }

  &__group,
  &__players {
    flex: 1 1 33.33%;
    min-width: 235px;

    @media (max-width: $max-xl) {
      max-width: 300px;
    }

    @media (min-width: $xl) {
      flex-basis: 100%;
    }
  }

  &__group {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 25px;

    &-level {
      display: flex;
      align-items: center;
      gap: 20px;

      &-image {
        width: 45px;
        height: 45px;

        & + span {
          font-size: 14px;
        }
      }
    }

    &-time {
      font-size: 20px;
    }

    &-type {
      margin-top: auto;
      padding: 15px 15px 10px;
      font-size: 14px;
      border-radius: 48px;
      background-color: $white;
      color: $black;
      line-height: 1;

      @media (min-width: $lg) {
        padding: 20px 25px 15px;
        font-size: 20px;
      }
    }
  }

  &__players {
    @media (min-width: $xl) {
      text-align: right;
    }

    p {
      margin-bottom: 14px;

      @media (min-width: $xl) {
        margin-bottom: 24px;
      }

      &:first-child {
        font-size: 14px;
      }

      &:last-child {
        font-size: 20px;
      }
    }
  }
}
