@import '../../../assets/styles/variables.scss';

.app-page-bottom-decor {
  position: absolute;
  bottom: 0;
  right: 0;
  z-index: -1;
  width: 100%;

  @media (max-width: $max-lg) {
    height: 300px;
  }

  img {
    width: 100%;

    @media (max-width: $max-lg) {
      height: 100%;
      object-fit: cover;
    }
  }
}
