@import "../../assets/styles/variables";

.product-preview {
  &__image {
    height: 220px;

    @media (min-width: $lg) {
      height: 370px;
    }

    & img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  &__content {
    margin: 30px 0;

    &-title {
      margin-bottom: 12px;
      font-family: $font-title;
      font-size: 16px;
      line-height: 1;
      color: $green;

      @media (min-width: $sm) {
        height: 16px;
        overflow: hidden;
      }

      @media (min-width: $lg) {
        height: 24px;
        font-size: 24px;
      }
    }

    &-subtitle {
      margin: 12px 0;
      font-size: 14px;

      @media (min-width: $sm) {
        height: 40px;
        overflow: hidden;
      }

      @media (min-width: $lg) {
        height: 55px;
        font-size: 20px;
      }
    }
  }

  &__buy {
    display: flex;
    justify-content: center;
    align-items: center;
    max-width: 250px;
    font-family: $font-title;
  }
}
