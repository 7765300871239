@import "variables";
@import "mixins";

// General
* {
  &::-webkit-scrollbar {
    width: 6px;
    height: 6px;
    background-color: rgba(0, 0, 0, .4);
  }

  &::-webkit-scrollbar-thumb {
    background-color: $green;
  }

  // Для firefox
  scrollbar-color: $green rgba(0, 0, 0, .4);
}

body {
  position: relative;
  min-height: 100vh;
  background-color: $graphite;
  font-family: $font-text;
  line-height: 1.33;
}

// Typography
h1,
h2,
h3,
h4,
h5,
h6,
button {
  font-family: $font-title;
  color: $white;
}

a {
  list-style-type: none;
  color: $white;
  transition: .2s ease;

  @media (any-hover: hover) {
    &:hover {
      color: $yellow;
    }
  }
}

button,
a {
  cursor: pointer;
}

p {
  color: $white;
}

// Grid
.wrapper {
  max-width: 1440px;
  margin: 0 auto;
  padding: 0 165px 0 165px;
}

.container {
  position: relative;
  padding: 0 15px;
  transition: none;

  @media (min-width: $md) {
    padding: 0 30px;
  }

  @media (min-width: $lg) {
    margin: 0 auto;
    max-width: 1170px;
  }
}

// Page text
.page-title {
  margin-bottom: 30px;
  font-size: 24px;
  font-weight: 400;
  line-height: 1;

  @media (min-width: $lg) {
    margin-bottom: 60px;
    font-size: 40px;
  }
}

.page-subtitle {
  margin-bottom: 30px;
  font-size: 14px;

  @media (min-width: $lg) {
    margin-bottom: 60px;
    font-size: 18px;
  }
}

.details-list {
  font-size: 14px;

  @media (min-width: $md) {
    font-size: 18px;
  }

  &__item {
    margin-bottom: 20px;
    padding-bottom: 7px;
    @include borderB(1, dashed);

    &:last-child {
      margin-bottom: 0;
    }

    &-text {
      display: flex;
      justify-content: space-between;
      gap: 20px;

      &-value {
        font-weight: 700;
        color: $green;
        text-align: right;
      }
    }
  }
}

.details-title {
  margin-bottom: 15px;
  font-family: $font-title;
  font-size: 24px;
  color: $green;
  line-height: 1;
}

.price {
  display: flex;
  align-items: flex-end;
  gap: 20px;
  font-family: $font-title;

  &__current {
    font-size: 24px;
    line-height: 1;
    color: $green;
  }

  &__old {
    font-size: 16px;
    color: $red;
    text-decoration: line-through;
    opacity: 0.7;
  }
}

// Buttons
.btn {
  width: 100%;
  height: 50px;
  @include border();
  cursor: pointer;
  transition: .5s ease;

  &:hover {
    background-color: $green;
  }

  &--bg {
    background-color: $green;

    &:hover {
      color: $black;
      border-color: $yellow;
      background-color: $yellow;
    }
  }

  &--octagon {
    display: block;
    margin: 30px auto 0;
    max-width: 250px;
    clip-path: polygon(15px 0%, calc(100% - 15px) 0%, 100% 15px, 100% calc(100% - 15px), calc(100% - 15px) 100%, 15px 100%, 0% calc(100% - 15px), 0% 15px);
  }
}

.no-reviews {
  font-size: 20px;
  color: $grey;
}

.add-review {
  display: block;
  margin: 45px auto 0;
  max-width: 445px;
}

// CKE editor style
.cke-description {
  h3 {
    margin-bottom: 15px;

    @media (min-width: $md) {
      margin-bottom: 30px;
    }
  }

  ul {
    font-size: 14px;

    @media (min-width: $md) {
      font-size: 18px;
    }

    li {
      margin-bottom: 20px;
      padding-bottom: 7px;
      @include borderB(1, dashed);
    }
  }

  table {
    width: 100%;
    font-size: 14px;
    @include border(0);
    border-collapse: collapse;

    @media (min-width: $md) {
      font-size: 18px;
    }

    tr {
      display: flex;
      justify-content: space-between;
      gap: 20px;
      padding-bottom: 7px;
      @include borderB(1, dashed);

      &:not(:last-child) {
        margin-bottom: 20px;
      }
    }

    td {
      padding: 0;
      border: 0;

      &:first-child {
        color: $white;
      }

      &:not(:first-child) {
        color: $green;
        text-align: right;
      }

      p {
        color: inherit;
      }
    }
  }
}

// Custom MUI dropdown
.MuiMenu-paper.dropdown-menu {
  margin-top: 5px;
  padding: 10px;
  border-radius: 0;
  background-color: $graphite;
  @include border();
  color: $white;

  .MuiMenu-list {
    padding: 0;
  }

  .MuiMenuItem-root {
    padding: 0;
    font-size: 12px;
    transition: .2s ease;

    &:not(:last-child) {
      margin-bottom: 10px;
    }

    @media (any-hover: hover) {
      &:hover {
        color: $yellow;
      }
    }
  }
}

// Custom MUI dialog
.MuiDialog-paper.dialog-popup {
  padding: 20px;
  border-radius: 0;
  @include border(6);
  background: $graphite;

  @media (min-width: $md) {
    padding: 50px;
    max-width: 700px;
    border-width: 10px;
  }

  .dialog-close {
    position: absolute;
    right: 16px;
    top: 16px;
    cursor: pointer;

    @media (min-width: $md) {
      right: 40px;
      top: 40px;
    }

    @media (min-width: $lg) {
      right: 50px;
      top: 50px;
    }
  }

  .dialog-title {
    margin-bottom: 30px;
    padding: 0 60px 0 0;
    font-size: 20px;
    font-family: $font-title;

    @media (min-width: $xs) {
      font-size: 24px;
    }

    @media (min-width: $lg) {
      margin-bottom: 40px;
      font-size: 30px;
    }
  }

  .dialog-content {
    padding: 0;
    color: $white;
  }

  .dialog-actions {
    margin-top: 50px;
    padding: 0;
    gap: 35px;

    @media (max-width: $max-sm) {
      display: block;
    }

    .btn {
      margin-left: 0;

      &:first-child {
        @media (max-width: $max-sm) {
          margin-bottom: 15px;
        }
      }
    }
  }
}

// Swiper
.swiper-slide img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.swiper-horizontal .swiper-pagination {
  bottom: 2px !important;
  height: 17px;

  @media (min-width: $xs) {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 4px;
  }

  @media (min-width: $md) {
    gap: 32px;
  }

  &-bullet {
    width: 11px;
    height: 11px;
    background: $white;
    opacity: 1;

    &-active {
      box-shadow: 0 0 0 3px $green;
    }
  }
}

// Other
.reviews {
  &__item:not(:last-child) {
    margin-bottom: 25px;

    @media (min-width: $md) {
      margin-bottom: 30px;
    }

    @media (min-width: $lg) {
      margin-bottom: 40px;
    }
  }
}

.media-wrapper {
  position: relative;

  video,
  iframe,
  img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

.no-scroll {
  overflow: hidden;
}

.hidden {
  display: none!important;
}

@media #{$tablet} {
  .wrapper {
    max-width: 744px;
    padding: 0 30px 0 30px;
  }
}

@media #{$mobile} {
  .wrapper {
    max-width: 390px;
    padding: 0 15px 0 15px;
  }
}
