@import '../../assets/styles/variables';
#transition-modal-title {
  padding-top: 15px;
}
.radio-group {
  font-family: $font-title;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 15px;
  padding-top: 40px;
  .btn--octagon {
    margin: 0;
    &:disabled {
      cursor: not-allowed;
      opacity: 0.5;
      pointer-events: none;
    }
  }
}
