@import './../../../assets/styles/variables';

.training-history {
  font-family: $font-title;
  color: $white;

  @media (max-width: $max-md) {
    max-height: calc(90vh - 127px);
  }

  &-popup {
    max-width: 1130px;
  }

  &__item {
    &:not(:last-child) {
      margin-bottom: 30px;

      @media (min-width: $lg) {
        margin-bottom: 50px;
      }
    }

    &-title {
      margin-bottom: 24px;
      font-size: 20px;
      font-weight: 400;
    }
  }

  &-list {
    display: flex;
    gap: 20px;
    flex-wrap: wrap;

    @media (min-width: $lg) {
      gap: 20px 40px;
    }

    @media (min-width: $xl) {
      gap: 20px 30px;
    }

    &__item {
      flex: 1 1 100%;

      @media (min-width: $xs) {
        max-width: calc(50% - 15px);
      }

      @media (min-width: $md) {
        max-width: calc(33.33% - 17px);
      }

      @media (min-width: $lg) {
        max-width: calc(25% - 33px);
      }

      @media (min-width: $xl) {
        max-width: calc(20% - 27px);
      }
    }
  }

  &-notification {
    margin-bottom: 30px;
    padding: 10px;
    font-family: Open Sans,Helvetica,Arial,sans-serif;
    font-size: 12px;
    border: 1px solid $yellow;
    color: $yellow;
    line-height: 1.33;

    @media (min-width: $lg) {
      font-size: 14px;
    }

    a {
      color: $green;
      font-weight: 600;
    }
  }
}

.show-msg .training-history {
  max-height: calc(90vh - 227px);

  @media (min-width: $lg) {
    max-height: calc(90vh - 290px);
  }
}
