@import "../../assets/styles/variables";

// Section
.product-info-section,
.related-products-section {
  margin: 30px 0;

  @media (min-width: $md) {
    margin-top: 40px;
    margin-bottom: 40px;
  }

  @media (min-width: $lg) {
    margin-top: 120px;
    margin-bottom: 120px;
  }

  &:last-child {
    margin-bottom: 0;
  }
}

// Control categories and products block
.control {
  @media (min-width: $md) {
    display: flex;
    gap: 20px;
  }

  @media (min-width: $lg) {
    gap: 30px;
  }

  &__block {
    @media (min-width: $md) {
      flex-basis: 50%;
    }

    &:not(:last-child) {
      @media (max-width: $max-md) {
        margin-bottom: 60px;
      }
    }
  }

  &__item {
    padding: 14px;
    width: 100%;
    border: $border-1;
    line-height: 1;
    transition: .2s ease;

    @media (min-width: $md) and (max-width: $max-lg) {
      padding: 8px;
    }

    @media (min-width: $lg) {
      font-size: 24px;
    }

    &:not(:last-child) {
      margin-bottom: 15px;

      @media (min-width: $md) {
        margin-bottom: 20px;
      }

      @media (min-width: $lg) {
        margin-bottom: 30px;
      }
    }

    @media (any-hover: hover) {
      &:hover {
        background-color: $green;
        cursor: pointer;
      }
    }
  }
}

.select-product {
  position: relative;
  z-index: 1;
  padding: 20px;
  border: $border-2;
  clip-path: polygon(100% 0, 100% calc(100% - 65px), calc(100% - 65px) 100%, 0 100%, 0 0);

  @media (min-width: $lg) {
    clip-path: polygon(100% 0, 100% calc(100% - 100px), calc(100% - 100px) 100%, 0 100%, 0 0);
  }

  &:after {
    content: '';
    transform: rotate(-45deg);
    position: absolute;
    right: -15px;
    bottom: 30px;
    width: 92px;
    height: 2px;
    background-color: $green;

    @media (min-width: $lg) {
      right: -23px;
      bottom: 48px;
      width: 144px;
      height: 2px;
    }
  }

  &__categories {
    @media (min-width: $md) {
      padding-top: 12px;
    }

    @media (min-width: $lg) {
      padding-top: 20px;
    }
  }

  &__products {
    padding: 12px 40px;
    background: rgba(114, 191, 68, .3);
    clip-path: polygon(100% 0, 100% calc(100% - 43px), calc(100% - 43px) 100%, 0 100%, 0 0);

    @media (min-width: $md) {
      padding-bottom: 25px;
    }

    @media (min-width: $lg) {
      padding: 20px 60px 40px;
      clip-path: polygon(100% 0, 100% calc(100% - 79px), calc(100% - 79px) 100%, 0 100%, 0 0);
    }
  }
}

// Product info block
.product {
  @media (min-width: $md) {
    display: flex;
    gap: 25px;
  }

  @media (min-width: $lg) {
    gap: 30px;
  }

  &__title,
  &__description,
  &__price {
    margin-bottom: 30px;
  }

  &__image {
    height: 280px;

    @media (max-width: $max-md) {
      margin-bottom: 30px;
    }

    @media (min-width: $md) {
      height: 330px;
      width: 50%;
    }

    @media (min-width: $lg) {
      max-width: 445px;
      height: 445px;
    }

    & img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  &__info {
    @media (min-width: $md) {
      width: 50%;
    }
  }

  &__description {
    &-title {
      display: inline-block;
      margin-bottom: 6px;
      font-size: 14px;
      color: $grey;
    }

    &-list ul {
      padding-left: 20px;
      color: $white;
      list-style: disc;

      @media (min-width: $lg) {
        font-size: 18px;
      }
    }
  }

  &__price {
    @media (min-width: $lg) {
      gap: 40px;
    }

    &-block:not(:last-child) {
      margin-bottom: 20px;
    }

    &-title {
      margin-bottom: 10px;
      font-size: 14px;
    }

    .price {
      gap: 10px 40px;
      flex-wrap: wrap;

      &__title {
        display: block;
        margin-bottom: 5px;
        font-family: $font-text;
        font-size: 18px;
      }
    }

    .price__block--current {
      color: $green;
    }

    .price__block--old {
      color: $red;
    }

    .price__current {
      @media (min-width: $lg) {
        font-size: 40px;
      }
    }

    .price__old {
      font-size: 24px;
      opacity: 1;
      text-decoration: none;
      line-height: 1;

      @media (min-width: $lg) {
        font-size: 40px;
      }
    }

    &-total {
      font-family: $font-title;
      font-size: 32px;
      color: $white;
    }
  }

  &__buy {
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: $font-title;
  }
}

// Related products slider
.related-products {
  &-section {
    margin: 60px 0;
  }

  &__slider {
    padding-bottom: 50px;

    & .swiper-pagination {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 40px;
      height: 22px;
      bottom: 0;
    }
  }
}
