@import "../../../assets/styles/variables";

.dialog-freeze-content {
  &__item {
    margin-bottom: 20px;
  }

  &__title {
    margin-bottom: 5px;
    font-size: 14px;
    color: #a5a5a5;
  }
  
  &__text {
    font-size: 18px;
    color: $white;

    span {
      color: $green;
    }
  }
}
