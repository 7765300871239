@import "../../../assets/styles/variables";

.speedometer__grade path {
  transition: .1s ease;
}

.easy {
  path:nth-child(1),
  path:nth-child(2),
  path:nth-child(3) {
    fill: currentColor;
  }
}

.middle {
  path:nth-child(1),
  path:nth-child(2),
  path:nth-child(3),
  path:nth-child(7),
  path:nth-child(8),
  path:nth-child(9) {
    fill: currentColor;
  }
}

.hard {
  path {
    fill: currentColor;
  }
}
