@import '../../../assets/styles/variables';

// Class preview
.class {
  position: relative;
  margin: 0 -10px;
  padding: 16px 5px;
  height: 100%;
  border: 2px solid;
  text-align: center;
  &__container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    min-height: 150px;
    @media (max-width: 940px) {
      min-height: 165px;
    }
  }

  @media (min-width: $lg) {
    margin: 0 -17px;
    padding: 25px 10px;
    border-width: 3px;
    &__container {
      min-height: 215px;
    }
  }
  @media (max-width: $lg) {
    min-height: 165px;
  }

  &__item {
    margin-bottom: 8px;

    @media (min-width: $lg) {
      margin-bottom: 12px;
    }
  }

  &__time {
    @media (max-width: $max-lg) {
      font-size: 9px;
    }

    @media (min-width: $lg) {
      font-size: 15px;
    }
  }

  &__training-type,
  &__trainer-name,
  &__join {
    font-size: 8px;

    @media (min-width: $lg) {
      font-size: 10px;
    }
  }

  &__image {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    width: 50px;
    height: 50px;
    overflow: hidden;

    @media (min-width: $lg) {
      width: 85px;
      height: 85px;
    }
  }

  &__filled {
    display: flex;
    justify-content: center;
    gap: 4px;

    @media (min-width: $lg) {
      gap: 6px;
    }

    &-item {
      width: 7px;
      height: 7px;
      border: 1px solid;

      @media (min-width: $lg) {
        width: 11px;
        height: 11px;
      }
    }

    &-number {
      font-weight: 400;
      line-height: 1;
    }
  }
  &__join {
    margin-bottom: 0;
    padding-top: 2px;
    max-width: 60px;
    width: 100%;
    height: 30px;
    border-radius: 20px;
    color: $graphite;
    cursor: pointer;
    &:disabled {
      opacity: 0.5;
      cursor: not-allowed;
    }

    @media (min-width: $lg) {
      max-width: 95px;
      height: 30px;
    }
    // @media (max-width: $max-lg) {
    //   height: 25px;
    // }
  }
  &__leaveReserve {
    font-size: 0.4rem;
    @media (min-width: $lg) {
      font-size: 0.6rem;
    }
  }

  &__reserve {
    font-size: 0.4rem;
    margin-bottom: 0;
    padding-top: 2px;
    max-width: 60px;
    width: 100%;
    height: 30px;
    border-radius: 20px;
    color: $graphite;
    cursor: pointer;
    @media (min-width: 721px) and (max-width: $max-md) {
      margin-bottom: 30px;
    }

    @media (min-width: $max-lg) {
      font-size: 0.6rem;
      max-width: 95px;
      height: 30px;
    }
  }

  &__reserved {
    margin-top: 0;
    // font-family: $font-text;
    font-size: 10px;
    display: block;
    margin-bottom: 0;
    // font-weight: 600;
    font-style: italic;
    color: red;
    @media (min-width: $lg) {
      font-size: 12px;
    }
  }

  &.joined .class__join {
    padding: 5px;
    height: 30px;
    color: $white;
    border: 1px solid;
  }
}

// Abonement popup
.class .select-abonement-popup {
  padding: 40px;
  max-width: 500px;

  &-wrapper {
    z-index: 10;
  }

  .popup__title {
    padding-top: 20px;
    font-size: 26px;
    padding-right: 0;
  }

  .abonement-list__item {
    max-width: 200px;
    display: block;
    margin: 0 auto;
    height: 40px;
    border-radius: 20px;

    &:not(:last-child) {
      margin-bottom: 20px;
    }
  }

  .popup__close {
    right: 5%;
    top: 5%;
  }
}

// Ended class
.ended {
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  //justify-content: flex-end;
  justify-content: center;
  gap: 20px;
  padding: 5px;
  width: 100%;
  height: 100%;
  background-color: rgba(126, 126, 126, 0.9);

  @media (min-width: $lg) {
    gap: 30px;
    padding: 15px;
  }

  p {
    font-size: 10px;
    word-break: normal;

    @media (min-width: $lg) {
      font-size: 14px;
    }
  }

  button {
    margin-bottom: 10px;
    max-width: 90px;
    height: 25px;
    font-size: 10px;
    border-radius: 20px;

    @media (min-width: $lg) {
      margin-bottom: 25px;
    }
  }
}

// Popup alert
.popup-alert {
  transform: translate(-50%, -50%);
  position: absolute;
  top: 50%;
  left: 50%;
  padding: 60px 40px;
  width: 90%;
  max-width: 670px;
  max-height: 90vh;
  border: 6px solid $green;
  background: $graphite;
  text-align: center;
  z-index: 2;

  @media (min-width: $lg) {
    padding: 80px 60px;
    border-width: 10px;
  }

  .popup__close {
    right: 25px;
    top: 25px;
  }

  &-wrapper.MuiModal-root {
    z-index: 4;
  }
}
