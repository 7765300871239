@import './src/assets/styles/variables';

.header {
  padding: 30px 0;
  position: sticky;
  top: 0;
  z-index: 10;
  background-color: $graphite;
  margin-bottom: 55px;
  background-image: url('./background-top.png');
  background-size: 160%;
  background-repeat: no-repeat;
  @media (min-width: $lg) {
    margin-bottom: 55px;
  }

  &__content {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 30px;
  }

  &__logo {
    flex: 0 0 80px;

    @media (min-width: 420px) {
      flex-basis: 100px;
    }

    @media (min-width: $sm) {
      flex-basis: 130px;
    }

    @media (min-width: $lg) {
      flex-basis: 280px;
    }
  }

  &__nav {
    @media (max-width: $max-lg) {
      display: none;
    }
  }

  &__list {
    display: flex;
    gap: 30px;
  }

  &__icon {
    width: 20px;
    height: 20px;
    transition: 0.2s ease;

    @media (min-width: 420px) {
      width: 24px;
      height: 24px;
    }

    svg {
      width: 100%;
      height: 100%;
    }

    &--burger {
      @media (min-width: $lg) {
        display: none;
      }
    }

    &--logout {
      display: flex;
      align-items: center;
      justify-content: center;

      svg {
        width: 75%;
        height: 75%;
      }
    }
  }

  &__icons {
    display: flex;
    align-items: center;
    gap: 10px;

    @media (min-width: $xs) {
      gap: 18px;
    }
  }

  &__item a {
    font-size: 18px;
  }

  &__item > a {
    display: block;
    padding-top: 10px;
    padding-bottom: 10px;
  }
}

// Abonement informer
.abonement-informer {
  position: relative;
  margin-right: 13px;
  margin-left: 13px;

  @media (min-width: $sm) {
    margin-right: 16px;
    margin-left: 16px;
  }

  &__icon {
    position: absolute;
    top: -1px;
    height: 29px;
    width: 38px;
    color: $green;

    @media (max-width: $max-sm) {
      overflow: hidden;
    }

    @media (min-width: $sm) {
      width: auto;
      height: 37px;
    }

    &--left {
      left: -13px;

      @media (min-width: $sm) {
        left: -17px;
      }
    }

    &--right {
      transform: scale(-1, 1);
      right: -10px;

      @media (min-width: $sm) {
        right: -14px;
      }
    }

    svg {
      width: auto;
      height: 100%;
    }
  }

  &__link {
    display: flex;
    align-items: center;
    gap: 8px;
    padding: 0 10px;
    height: 28px;
    font-size: 10px;
    border: $border-2;
    border-left: 0;
    background-color: $graphite;
    clip-path: polygon(15px 0%, calc(100% - 15px) 0%, 100% 50%, calc(100% - 15px) 100%, 15px 100%, 0% 50%);

    @media (min-width: $sm) {
      padding: 0 19px;
      min-width: 64px;
      height: 35px;
    }

    &:hover {
      background-color: $yellow;
      color: $black;

      svg path,
      svg circle {
        fill: $black;
      }
    }

    &-icon {
      display: flex;
      width: 16px;

      @media (min-width: $sm) {
        width: 22px;
      }
    }

    &-text {
      @media (max-width: $max-xl) {
        display: none;
      }
    }
  }
}

.is-abonement .abonement-informer__link {
  box-shadow: 2px -2px 21px 0px #56ff3a80 inset;
  pointer-events: none;
}

.no-abonement .abonement-informer__link {
  path,
  circle {
    fill: $grey;
    transition: 0.2s ease;
  }
}
