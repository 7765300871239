@import '../../assets/styles/variables';

.page-title--account {
  @media (min-width: $md) {
    margin-bottom: 0;
  }

  @media (min-width: $lg) {
    margin-bottom: 30px;
  }
}

.account {
  h3 {
    margin-bottom: 30px;
    font-size: 12px;

    @media (min-width: $md) {
      margin-bottom: 10px;
      font-size: 14px;
      color: #bfbfbf;
    }

    @media (min-width: $lg) {
      font-size: 16px;
    }
  }

  &-description-title {
    display: block;
    margin-bottom: 8px;
    font-size: 12px;
    color: $grey;

    @media (min-width: $md) and (max-width: $max-lg) {
      margin-bottom: 4px;
    }

    @media (min-width: $md) {
      font-weight: 700;
    }

    @media (min-width: $md) and (max-width: $lg) {
      font-size: 10px;
    }

    &--abonement {
      display: flex;
      align-items: center;
      gap: 5px;
    }
  }
  &-change-password-btn {
    display: block;
    margin-top: 14px;
    font-size: 12px;
    color: $white;
    width: 100px;
    height: 30px;
    @media (min-width: $md) {
      border-width: 2px;
      box-shadow: $shadow-inset-2;
    }
  }
  & .freezed {
    opacity: 0.5;
  }
  &-description-text {
    font-size: 14px;

    @media (min-width: $md) and (max-width: $lg) {
      font-size: 12px;
    }
    @media (max-width: $max-md) {
      font-size: 12px;
    }
  }

  &-no-info {
    font-size: 14px;
    color: $grey;
  }
}

.btn--freeze-abonement {
  padding: 8px 10px 5px;
  height: auto;
  width: auto;
  line-height: 0.6;
  font-size: 10px;
  opacity: 1 !important;
}

// Edit profile button
.btn-edit-profile-wrapper {
  @media (min-width: $md) {
    flex: 1 1 100%;
  }

  @media (min-width: $lg) {
    order: -1;
  }
}

.btn--edit-profile {
  margin-bottom: 30px;
  height: 40px;
  font-size: 12px;

  @media (min-width: $sm) {
    max-width: 220px;
  }

  @media (min-width: $sm) and (max-width: $max-md) {
    position: absolute;
    top: 0;
    right: 0;
  }

  @media (min-width: $md) {
    display: block;
    margin-bottom: 10px;
    margin-left: auto;
    max-width: 210px;
    height: 35px;
    border-width: 2px;
    box-shadow: $shadow-inset-2;
  }
}

// User info
.user-info {
  &__tabs-nav {
    display: flex;
    font-family: $font-title;
    font-size: 12px;

    @media (min-width: $md) {
      position: relative;
      font-size: 14px;
    }

    &:after {
      @media (min-width: $md) {
        content: '';
        position: absolute;
        right: 0;
        bottom: -2px;
        width: calc(50% - 21px);
        height: 2px;
        background: $green;
      }
    }

    &.contacts-nav:after {
      @media (min-width: $md) {
        right: auto;
      }
    }

    .tabs-title {
      position: relative;
      flex: 1 1 50%;
      margin-bottom: 5px;
      padding: 15px 25px;
      border: $border-2;
      color: #bfbfbf;

      @media (min-width: $md) {
        padding: 15px 15px 10px;
      }

      @media (min-width: $lg) {
        text-align: center;
      }

      @media (any-hover: hover) {
        &:hover {
          cursor: pointer;
        }
      }

      &:not(.selected) {
        @media (min-width: $md) {
          box-shadow: $shadow-inset-2;
        }
      }

      &.selected {
        margin-bottom: -2px;
        border-bottom: 0;
        color: #fff;
        clip-path: polygon(0 0, calc(100% - 53px) 0, 100% 100%, 0 100%);

        @media (min-width: $lg) {
          font-size: 20px;
        }

        &:before {
          transform: rotate(-43deg);
          right: 25px;
          height: 77px;

          @media (min-width: $md) {
            transform: rotate(-44.2deg);
            right: 24px;
          }
        }

        &:after {
          @media (min-width: $md) {
            content: '';
            position: absolute;
            height: 150%;
            top: 0;
            left: 0;
            width: 100%;
            box-shadow: $shadow-inset-2;
          }
        }
      }

      &:before {
        content: '';
        position: absolute;
        top: -12px;
        width: 2px;
        height: 70px;
        background-color: $green;
      }

      &--about {
        clip-path: polygon(0 0, 100% 0, calc(100% - 45px) 100%, 0px 100%);

        @media (min-width: $lg) {
          padding-right: 45px;
        }

        &:before {
          transform: rotate(42deg);
          right: 21px;

          @media (min-width: $md) {
            transform: rotate(43.5deg);
            right: 22px;
            box-shadow: $shadow-pseudo-inset;
          }
        }
      }

      &--contacts {
        margin-left: -45px;
        clip-path: polygon(0 0, 100% 0, 100% 100%, 45px 100%);

        @media (max-width: $max-lg) {
          text-align: right;
        }

        @media (min-width: $lg) {
          padding-left: 45px;
        }

        &:before {
          transform: rotate(-42deg);
          left: 20px;

          @media (min-width: $md) {
            transform: rotate(-43.3deg);
            left: 22px;
            box-shadow: $shadow-pseudo-inset;
          }
        }

        &.selected {
          clip-path: polygon(52px 0%, 100% 0, 100% 100%, 0px 100%);

          &:before {
            transform: rotate(42deg);
            left: 24px;

            @media (min-width: $md) {
              transform: rotate(43.5deg);
              left: 23px;
            }
          }
        }
      }
    }
  }

  &__tabs-content {
    position: relative;
    border-top: 0;

    @media (min-width: $md) {
      position: relative;
      padding-top: 30px;
      padding-bottom: 14px;
      margin-top: -18px;
      clip-path: polygon(0% 20px, 100% 20px, 100% 100%, 97px 100%, 0% calc(100% - 97px));
    }

    @media (max-width: $max-lg) {
      min-height: 310px;
    }

    @media (min-width: 993px) {
      padding-bottom: 16px;
    }

    @media (min-width: $lg) and (max-width: $lg) {
      padding-bottom: 22px;
    }

    @media (min-width: $md) and (max-width: $max-lg) {
      padding-top: 27px;
      min-height: 194px;
    }

    @media (min-width: $lg) {
      clip-path: polygon(0 20px, 100% 20px, 100% 100%, 100px 100%, 0 calc(100% - 100px));
      height: 230px;
    }

    &:before {
      @media (min-width: $md) {
        content: '';
        transform: rotate(45deg);
        position: absolute;
        bottom: 41px;
        left: -33px;
        width: 170px;
        height: 2px;
        background-color: $green;
        box-shadow: $shadow-pseudo-inset;
      }

      @media (min-width: $lg) {
        transform: rotate(45deg);
        bottom: 37px;
        left: -35px;
        width: 190px;
      }
    }

    &:after {
      content: '';
      position: absolute;
      top: 0;
      right: 0;
      width: calc(50% - 20px);
      height: 2px;
      background: $green;

      @media (min-width: $md) {
        top: 18px;
        box-shadow: -10px 0 17px 2px rgba(86, 255, 58, 0.5);
      }
    }

    &.contacts {
      @media (min-width: 993px), (max-width: $max-md) {
        // min-height: 244px;
      }

      @media (min-width: $lg) and (max-width: $lg) {
        min-height: 241px;
      }

      @media (min-width: $md) and (max-width: $max-lg) {
        min-height: 194px;
      }
    }

    &.contacts:after {
      left: -2px;
      width: calc(50% - 18px);

      @media (min-width: $md) {
        box-shadow: 10px 0 17px 2px rgba(86, 255, 58, 0.5);
      }
    }
  }

  &__content {
    @media (min-width: $md) {
      display: flex;
      flex-wrap: wrap;
    }

    &-item {
      @media (min-width: $md) {
        flex: 1 1 50%;
      }

      &:not(:last-child) {
        margin-bottom: 20px;
      }

      @media (min-width: $md) and (max-width: $max-lg) {
        &:not(:last-child) {
          margin-bottom: 9px;
        }
      }

      &--email,
      &--password {
        @media (min-width: $md) {
          // order: -1;
        }
      }

      &--email {
        .account-description-text {
          font-weight: 700;
          font-family: $font-title;
        }

        .account-description-text {
          font-family: $font-title;
        }
      }

      &--description p {
        font-size: 16px;
      }

      &--description {
        &-container {
          font-size: 14px;
        }

        &-visible {
          display: block;
        }

        &-hide {
          display: none;
        }

        &-readMore {
          font-family: 'Open Sans';
          font-weight: 400;
          font-size: 14px;
          line-height: 19.07px;
          text-align: right;
          color: #72bf44;
        }
      }

      &--description.readMore-container {
        width: 100%;
        padding-right: 100px;
        text-align: right;
      }

      &--description.readMore-container.expand {
        margin-bottom: 25px;
      }
    }
  }
}

.shape-float {
  @media (min-width: $md) {
    float: left;
    width: 85px;
    height: 100%;
    shape-outside: polygon(0 calc(100% - 75px), 0 100%, 75px 100%);
    clip-path: polygon(0 calc(100% - 75px), 0 100%, 75px 100%);
    shape-margin: 20px;
  }
}

.text-float p {
  @media (min-width: $md) {
    padding-right: 90px;
  }

  @media (min-width: $lg) {
    padding-right: 80px;
  }

  @media (min-width: $xl) {
    padding-right: 120px;
  }
}

// User preview
.user-preview {
  &__role h3 {
    margin-bottom: 10px;
  }
}

// User level
.user-level {
  h3 {
    @media (min-width: $md) {
      margin-left: 45px;
      text-align: center;
    }
  }

  &__content {
    display: flex;
    gap: 13px;
    align-items: center;

    @media (min-width: $md) {
      flex-direction: column;
      margin-left: 45px;
    }

    &-image {
      width: 50px;

      @media (min-width: $md) {
        width: 75px;
      }

      @media (min-width: $lg) {
        width: 100px;
      }
    }

    &-title {
      font-family: $font-title;
      font-size: 14px;
    }

    &-text {
      @media (max-width: $max-md) {
        margin-left: 38px;
      }

      @media (min-width: $md) {
        margin-top: 5px;
        text-align: center;
      }

      @media (min-width: $lg) {
        margin-top: 12px;
      }
    }
  }

  .account-description-text {
    font-family: $font-title;
  }
}

// Achievements
.achievements {
  &__images-wrapper {
    display: flex;
    flex-wrap: wrap;
    gap: 24px;

    @media (min-width: $md) {
      gap: 13px;
    }

    @media (min-width: $md) and (max-width: $max-lg) {
      max-height: 36px;
      overflow: hidden;
    }
  }

  h3,
  &__wrapper {
    @media (min-width: $md) and (max-width: $max-lg) {
      margin-left: 30px;
    }
  }

  h3 {
    @media (min-width: $lg) {
      text-align: right;
    }
  }
}

// Trainings
.trainings {
  position: relative;

  @media (min-width: $md) {
    margin-top: -100px;
    clip-path: polygon(26% 0, calc(26% + 100px) 100px, 100% 100px, 100% 100%, 0 100%, 0 40px, 40px 0);
  }

  &:before,
  &:after {
    @media (min-width: $md) {
      content: '';
      position: absolute;
      border-top: $border-2;
      box-shadow: $shadow-pseudo-inset;
    }
  }

  &:before {
    @media (min-width: $md) {
      transform: rotate(-45deg);
      top: 17px;
      left: -10px;
      width: 60px;
    }
  }

  &:after {
    @media (min-width: $md) {
      transform: rotate(45deg);
      top: 47px;
      left: calc(26% - 25px);
      width: 145px;
    }
  }

  &__heading {
    display: flex;
    gap: 20px;
    margin-bottom: 10px;
    text-wrap: nowrap;
    &-type {
      flex-shrink: 0;
      min-width: 115px;
    }

    &-left {
      flex-shrink: 0;
      width: 55px;
      text-align: center;
    }

    &-expiry {
      flex-shrink: 0;
      width: 100px;
    }
    @media (min-width: $max-md) and (max-width: 865px) {
      gap: 10px;
      &-type {
        min-width: 100px;
      }
    }
  }

  &__body {
    color: $white;

    &-item {
      display: flex;
      align-items: center;
      gap: 5px 20px;
      flex-wrap: wrap;
      text-wrap: nowrap;
      @media (min-width: $md) and (max-width: 865px) {
        gap: 5px 10px;
        font-size: 0.6rem;
      }
      @media (max-width: 505px) {
        display: grid;
        grid-template-rows: repeat(2, 1fr);
        grid-template-columns: repeat(4, 1fr);
        grid-template-areas:
          'variant remains validity'
          'freeze freeze freeze';
        .trainings__heading {
          &-type {
            grid-area: variant;
            text-wrap: nowrap;
          }
          &-left {
            grid-area: remains;
          }
          &-expiry {
            grid-area: validity;
            text-wrap: nowrap;
          }
          &-empty {
            grid-area: freeze;
          }
        }
      }
    }
  }

  &__item-wrapper {
    @media (min-width: $md) {
      padding-left: 10px;
    }
  }

  &__bottom-decor {
    @media (min-width: $md) {
      position: absolute;
      right: 0;
      top: calc(98px - 0px);
      display: block;
      width: calc(74% - 98px);
      border-top: $border-2;
    }
  }

  h3 {
    @media (min-width: $md) {
      margin-top: 14px;
    }
  }

  &--subscription {
    @media (min-width: $md) and (max-width: $max-lg) {
      margin-left: 75px;
    }
  }

  &--rest {
    overflow: auto;

    @media (min-width: $md) and (max-width: $max-lg) {
      width: 75%;
    }
  }

  .account-description-text {
    margin-bottom: 20px;
    font-family: $font-title;
    @media (max-width: $max-md) {
      margin-bottom: 30px;
      &:last-child {
        margin-bottom: 10px;
      }
    }
  }

  &__history {
    padding: 10px 13px;
    height: auto;
    width: auto;
    font-size: 10px;
    line-height: 1;

    @media (max-width: $max-md) {
      position: absolute;
      top: 0;
      right: 0;
    }

    @media (min-width: $xs) {
      font-size: 12px;
    }

    @media (min-width: $md) {
      display: block;
      margin-left: auto;
    }
  }

  .btn--change-password {
    margin-bottom: 30px;
    height: 40px;
    font-size: 12px;

    @media (min-width: $sm) {
      max-width: 220px;
    }

    @media (min-width: $sm) and (max-width: $max-md) {
      position: absolute;
      top: 0;
      right: 40%;
    }

    @media (min-width: $md) {
      display: block;
      margin-bottom: 10px;
      margin-left: auto;
      max-width: 210px;
      height: 35px;
      border-width: 2px;
      box-shadow: $shadow-inset-2;
    }
  }
}
