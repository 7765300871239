@import "../../assets/styles/variables";
.page {
  font-family: 'Docker', 'Open Sans' sans-serif;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  -moz-text-size-adjust: 100%;
  display: flex;
  min-height: 100vh;
  flex-direction: column;
  background-color: $darkgreen;
  color: $white;
}

