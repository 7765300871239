@import '../../../assets/styles/variables';

.schedule-main-filter {
  display: flex;
  gap: 35px;
  font-family: $font-title;
  color: $white;

  @media (min-width: $xl) {
    gap: 65px;
  }

  &__item {
    position: relative;
    z-index: 1;
  }

  &__title {
    position: relative;
    display: block;
    padding-right: 20px;
    font-size: 12px;
    line-height: 1;
    cursor: pointer;

    svg {
      transform: translateY(-50%);
      position: absolute;
      top: 40%;
      right: 0;
      color: $green;
      transition: 0.2s ease;
    }

    &.expanded {
      svg {
        transform: scale(-1);
        top: 20%;
      }
    }
  }
}

.reset-filter {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 20px;
  padding: 0 25px;
  height: 30px;
  font-size: 12px;
  background-color: $yellow;
  color: $graphite;
  border-radius: 20px;
  line-height: 1;
  border: 1px solid $yellow;
  transition: background-color, color, 0.2s ease;

  @media (min-width: $xs) {
    margin-left: 35px;
  }

  &:hover {
    background-color: transparent;
    color: $white;
  }
}

.selected {
  color: $yellow !important;
}
