@import "../../assets/styles/variables";

// Section style
.level-slider-section,
.level-video-section,
.level-info-section {
  position: relative;
  margin: 30px 0;

  @media (min-width: $md) {
    margin-top: 40px;
    margin-bottom: 40px;
  }
}

.level-video-section {
  @media (min-width: $md) {
    margin-top: 100px;
  }

  @media (min-width: $lg) {
    margin-top: 60px;
  }
}

.level-slider-section {
  @media (min-width: $lg) {
    position: absolute;
    left: 30px;
    margin-top: 0;
    width: calc(40% - 60px);
  }
}

// Level slider
.level-slider {
  @media (min-width: $md) and (max-width: $max-lg) {
    margin-left: 0;
    max-width: 50%;
  }

  .swiper-slide:not(.swiper-slide-active) {
    opacity: 0!important;
  }

  &__title {
    padding: 5px 0;
    font-family: $font-title;
    font-size: 18px;
    line-height: 1;
    text-align: center;

    @media (min-width: $md) {
      font-size: 20px;
    }

    @media (min-width: $lg) {
      font-size: 30px;
    }
  }

  .swiper-button-next,
  .swiper-button-prev {
    color: $white;

    &:after {
      font-size: 26px;
      font-weight: 700;
    }
  }
}

// Player level
.player-level {
  @media (min-width: $md) {
    display: flex;
    flex-wrap: wrap;
    gap: 30px;
  }

  @media (min-width: $lg) {
    gap: 60px 70px;
  }

  &__preview,
  &__preview-image,
  &__nav {
    margin-bottom: 30px;
  }

  &__preview,
  &__details {
    @media (min-width: $md) {
      flex: 1 1 calc(50% - 15px);
    }
  }

  &__preview {
    @media (min-width: $lg) {
      flex: 1 1 calc(40% - 35px);
    }
  }

  &__details, {
    @media (min-width: $lg) {
      flex: 1 1 calc(60% - 35px);
    }
  }

  &__nav {
    @media (min-width: $md) {
      order: -1;
      flex: 1 1 100%;
      margin-bottom: 0;
    }

    @media (min-width: $lg) {
      padding-left: calc(40% + 35px);
    }
  }

  &__nav-list {
    span {
      position: relative;
      display: block;
      padding: 10px 5px;
      opacity: .5;
      transition: .2s ease;
      cursor: pointer;

      &:after {
        content: '';
        transform: translateX(-50%);
        position: absolute;
        bottom: 0;
        left: 50%;
        width: 0;
        border-bottom: $border-1;
        transition: .2s ease;
      }

      @media (any-hover: hover) {
        &:hover {
          &:after {
            width: 100%;
          }
        }
      }
    }

    .active-tab span {
      opacity: 1;

      &:after {
        width: 100%;
      }
    }
  }

  &__preview-image {
    margin-left: auto;
    margin-right: auto;

    @media (max-width: $max-md) {
      width: 250px;
      height: 250px;
    }

    @media (min-width: $md) {
      margin-bottom: 22px;
    }

    img {
      object-fit: cover;
      width: 100%;
      height: 100%;
    }
  }

  // temp
  &__nav ul {
    display: flex;
    gap: 20px;
    font-size: 20px;
    color: $white;
    flex-wrap: wrap;

    @media (max-width: $max-md) {
      font-family: $font-title;
    }

    @media (min-width: $md) {
      justify-content: space-between;
      font-size: 18px;
      font-weight: 700;
    }

    @media (min-width: $lg) {
      height: 40px;
      align-items: center;
    }

    li {
      transition: color 0.2s ease;

      @media (any-hover: hover) {
        &:hover {
          color: $yellow;
        }
      }
    }
  }

  &__details-title {
    @media (max-width: $max-lg) {
      display: none;
    }

    @media (min-width: $lg) {
      margin-bottom: 35px;
      font-family: $font-title;
      font-size: 30px;
    }
  }
}

// Player level grade
// Скрыто по просьбо заказчика
//.grade {
//  display: flex;
//  justify-content: center;
//  align-items: center;
//  gap: 12px;
//  color: $white;
//
//  &__total {
//    display: flex;
//    justify-content: center;
//    align-items: center;
//    width: 50px;
//    height: 50px;
//    font-size: 20px;
//    font-weight: 600;
//    border-radius: 50%;
//    background-color: $darkgreen;
//  }
//
//  &__range {
//    padding: 7px 12px;
//    font-size: 14px;
//    border: $border-2;
//  }
//}

// Level video
.level-video {
  &__wrapper {
    position: relative;
    padding-top: 280px;
    clip-path: polygon(30px 0, calc(100% - 30px) 0, 100% 30px, 100% 100%, 236px 100%, 197px calc(100% - 30px), 0 calc(100% - 30px), 0 30px);

    @media (min-width: $lg) {
      padding-top: 460px;
    }
  }

  iframe,
  video {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  &__title {
    position: absolute;
    bottom: 0;
    left: 0;
    display: inline-block;
    padding: 7px;
    width: 210px;
    font-family: $font-title;
    font-size: 8px;
    color: $white;
    background-color: $black;
    clip-path: polygon(calc(100% - 13px) 0, 100% 50%, calc(100% - 13px) 100%, 0 100%, 0 0);
    text-align: center;
  }
}

// Speedometer
.speedometer {
  position: relative;
  width: 250px;
  height: 250px;
  background-color: #000;
  border-radius: 50%;
  box-shadow: 0 0 47px rgba(0, 0, 0, 0.7);

  @media (min-width: $md) {
    width: 300px;
    height: 300px;
  }

  @media (min-width: $lg) {
    width: 330px;
    height: 330px;
  }

  &__dash {
    display: block;
    width: 95%;
    height: 95%;
    position: absolute;
    transform: translate(-50%, -50%);
    top: 50%;
    left: 50%;

    path {
      stroke-dashoffset: 70.6958;
      stroke-dasharray: 1 2;
      stroke-width: 7px;
      stroke: #505050;
    }
  }

  &__lvl {
    position: relative;
    height: 100%;
    color: $white;

    &:after {
      content: "";
      transform: translate(-50%, -50%);
      position: absolute;
      top: 50%;
      left: 50%;
      border-radius: 50%;
      border: 14px solid;
      transition: .2s ease;

      @media (min-width: $lg) {
        border-width: 19px;
      }
    }
  }

  &__center-decor,
  &__grade {
    svg {
      width: 100%;
      height: 100%;
      transition: .2s ease;
    }
  }

  &__grade {
    transform: translate(-50%, -50%);
    position: absolute;
    top: 50%;
    left: 50%;
    width: 85%;
    height: 85%;
    color: $white;
  }

  &__center-decor {
    transform: translate(-50%, -50%);
    position: absolute;
    top: 50%;
    left: 50%;
    width: 120px;
    height: 120px;
  }

  &__arrow {
    transform: translate(-50%, -50%) rotate(110deg) scale(0.8);
    position: absolute;
    top: 50%;
    left: 50%;
    display: flex;
    height: 317px;
    color: $white;
    transition: .2s ease;
  }
}
