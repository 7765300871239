@import '../../../assets/styles/variables';

.popup-change-password {
  z-index: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: fixed;
  top: -100%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: $graphite;
  border: 8px solid $green;
  box-shadow: inset 33px 23px 250px rgba(114, 191, 68, 0.2);
  width: 490px;
  transition: 0.3s all ease-in;

  &__wrapper {
    position: relative;
    width: 100%;
    padding: 60px 50px;
  }

  &__close {
    width: 40px;
    height: 40px;
    cursor: pointer;
    position: absolute;
    right: 20px;
    top: 20px;

    svg {
      width: 100%;
      height: 100%;
    }
  }

  &__logo img {
    margin: 0 auto;
    width: 246px;
  }

  &--open {
    top: 50%;
    transition: 0.5s all;
  }
}

.popup-change-pass-form {
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding-top: 60px;
  width: 100%;

  label {
    font-size: 18px;
    line-height: 25px;
    color: $white;
  }

  input {
    width: 100%;
    border-bottom: 1px solid $white;
    font-size: 18px;
    line-height: 25px;
    cursor: text;
    padding-bottom: 10px;
  }

  input::placeholder {
    font-size: 15px;
    line-height: 25px;
  }
  & .invalid {
    color: red;
    font-size: 0.8rem;
  }
  &__btn {
    padding: 14.5px;
    border-radius: 48.4298px;
    background: $white;
    color: $black;
    font-weight: 400;
    font-size: 20px;
    line-height: 16px;
    text-transform: uppercase;
    cursor: pointer;
  }

  &__btn_white {
    background: $white;
    color: $black;
  }

  &__btn:hover {
    background: $yellow;
    color: $graphite;
    transition: all 0.5s ease-in;
  }
}

@media #{$mobile} {
  .popup-change-password {
    box-shadow: inset 33px 23px 250px rgba(114, 191, 68, 0.2);
    width: 358px;
    max-height: 370px;

    &__wrapper {
      padding: 45px 40px 45px 40px;
    }

    &__close {
      width: 25px;
    }

    &__logo img {
      margin: 0 auto;
      width: 195px;
    }
  }
  .popup-change-pass-form {
    padding-top: 40px;

    label {
      font-size: 14px;
      line-height: 19px;
    }

    input {
      font-size: 14px;
      line-height: 19px;
    }

    input::placeholder {
      font-size: 14px;
      line-height: 19px;
    }

    &__text {
      font-size: 13px;
      line-height: 19px;
    }

    &__btn {
      font-size: 14px;
      line-height: 14px;
      margin-top: 15px !important;
    }
  }
}

@media (max-width: 390px) {
  .popup-change-password {
    max-width: 290px;
    height: auto;

    &__wrapper {
      padding: 25px;
    }

    &__logo {
      width: 160px;
      margin: 0 auto;
    }

    &__text {
      text-align: center;
      padding: 0;
    }

    &__btn {
      padding: 10px;
    }

    &__close {
      top: 10px;
      right: 10px;
    }
  }
}
.popUp-enter {
  &-done {
    top: 50%;
    transition: 0.5s all;
  }
}

.popUp-exit {
  top: -100%;
}
